import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './App.css';
import { jsPDF } from 'jspdf';
import Auth from './Auth';
import { supabase } from './supabaseClient';
// import ReactMarkdown from 'react-markdown';

// Log production environment variables temproarily
if (process.env.NODE_ENV === 'production') {
    console.log('Production environment variables:', {
      REACT_APP_PROD_LIVE_MONTHLY_BASIC_STRIPE_PRICE_ID: process.env.REACT_APP_PROD_LIVE_MONTHLY_BASIC_STRIPE_PRICE_ID,
      REACT_APP_PROD_LIVE_MONTHLY_PRO_STRIPE_PRICE_ID: process.env.REACT_APP_PROD_LIVE_MONTHLY_PRO_STRIPE_PRICE_ID,
      REACT_APP_PROD_LIVE_ANNUAL_BASIC_STRIPE_PRICE_ID: process.env.REACT_APP_PROD_LIVE_ANNUAL_BASIC_STRIPE_PRICE_ID,
      REACT_APP_PROD_LIVE_ANNUAL_PRO_STRIPE_PRICE_ID: process.env.REACT_APP_PROD_LIVE_ANNUAL_PRO_STRIPE_PRICE_ID,
      REACT_APP_PROD_CONTENT_SERVICE_URL: process.env.REACT_APP_PROD_CONTENT_SERVICE_URL,
    });
  }

const exportAsPDF = (text, filename) => {
  // Convert array of objects to string if needed
  const formattedText = Array.isArray(text) 
    ? text.map(item => item.text || '').join('\n')
    : typeof text === 'object' 
      ? JSON.stringify(text, null, 2)
      : String(text || '');

  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 10;
  const maxWidth = pageWidth - (margin * 2);
  const lineHeight = 7;
  
  const lines = doc.splitTextToSize(formattedText, maxWidth);
  let y = margin;
  
  lines.forEach(line => {
    if (y > doc.internal.pageSize.getHeight() - margin) {
      doc.addPage();
      y = margin;
    }
    doc.text(line, margin, y);
    y += lineHeight;
  });
  
  doc.save(`${filename}.pdf`);
};

const exportAsTXT = (text, filename) => {
  const formattedText = Array.isArray(text)
    ? text.map(item => item.text || '').join('\n')
    : typeof text === 'object'
      ? JSON.stringify(text, null, 2)
      : String(text || '');

  const element = document.createElement('a');
  const file = new Blob([formattedText], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${filename}.txt`;
  document.body.appendChild(element);
  element.click();
};

const exportAsSRT = async (text, filename, totalDuration) => {
  const formattedText = Array.isArray(text)
    ? text.map(item => item.text || '').join('\n')
    : typeof text === 'object'
      ? JSON.stringify(text, null, 2)
      : String(text || '');

  const lines = formattedText.split('\n').filter(line => line.trim() !== '');
  let srtContent = '';
  
//   const totalDuration = await getAudioDuration(file);
  const timePerLine = totalDuration / lines.length;
  
  lines.forEach((line, index) => {
    const startTime = index * timePerLine;
    const endTime = Math.min((index + 1) * timePerLine, totalDuration);
    
    const startFormatted = formatSRTTime(startTime);
    const endFormatted = formatSRTTime(endTime);
    
    srtContent += `${index + 1}\n`;
    srtContent += `${startFormatted} --> ${endFormatted}\n`;
    srtContent += `${line}\n\n`;
  });
  
  const element = document.createElement('a');
  const file = new Blob([srtContent], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${filename}.srt`;
  document.body.appendChild(element);
  element.click();
};
  
  const formatSRTTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 1000);
    
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')},${String(ms).padStart(3, '0')}`;
  };

  const getAudioDuration = (file) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = reject;
      audio.src = URL.createObjectURL(file);
    });
  };

  function Menu({ onNavigate, onGenerateContent, subscriptionStatus, handleSignOut }) {    
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleGenerateContent = () => {
      if (subscriptionStatus === 'active' || subscriptionStatus === 'cancel_pending') {
        setIsModalOpen(true);
      }
    };
  
    const handleModalClose = () => {
      setIsModalOpen(false);
    };
  
    const handleProjectNameSubmit = (projectName, speakerNames, guestName) => {
      setIsModalOpen(false);
      onGenerateContent(projectName, speakerNames, guestName);
    };
  
    const isSubscriptionActive = subscriptionStatus === 'active' || subscriptionStatus === 'cancel_pending';
  
    return (
      <div className="menu">
        <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>
        <button onClick={() => onNavigate('subscription')} className="manage-subscription-button">Manage Subscription</button>
        {!isSubscriptionActive && (
            <p className="subscription-warning">Please activate a subscription to use the app</p>
        )}
        <h1>Podcast Content Generator</h1>
        <button 
          className={`menu-button ${!isSubscriptionActive ? 'disabled' : ''}`} 
          onClick={handleGenerateContent}
          disabled={!isSubscriptionActive}
        >
          Generate Content
        </button>
        <button 
          className={`menu-button ${!isSubscriptionActive ? 'disabled' : ''}`} 
          onClick={() => onNavigate('saved')}
          disabled={!isSubscriptionActive}
        >
          Saved Projects
        </button>
        {isModalOpen && (
          <ProjectNameModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            onSubmit={handleProjectNameSubmit}
            initialProjectName={''}
          />
        )}
      </div>
    );
  }

  function ProjectNameModal({ isOpen, onClose, onSubmit, initialProjectName }) {
    const [projectName, setProjectName] = useState(initialProjectName || '');
    const [speakerCount, setSpeakerCount] = useState(2);
    const [speakerNames, setSpeakerNames] = useState(['', '']);
    const [guestSpeakers, setGuestSpeakers] = useState([false, false, false, false]); // Now includes Speaker 1
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Find the first speaker marked as guest and use their name as guestName
      const guestIndex = guestSpeakers.findIndex(isGuest => isGuest);
      // No need to add 1 to the index anymore since guestSpeakers now includes Speaker 1
      const speakerIndex = guestIndex >= 0 ? guestIndex : -1;
      const guestName = speakerIndex >= 0 ? speakerNames[speakerIndex] : null;
      onSubmit(projectName, speakerNames, guestName);
    };

    const handleSpeakerNameChange = (index, value) => {
      const newSpeakerNames = [...speakerNames];
      newSpeakerNames[index] = value;
      setSpeakerNames(newSpeakerNames);
    };

    const handleSpeakerCountChange = (e) => {
      const count = parseInt(e.target.value);
      setSpeakerCount(count);
      setSpeakerNames(prevNames => {
        const newNames = [...prevNames];
        while (newNames.length < count) {
          newNames.push('');
        }
        return newNames.slice(0, count);
      });
      // Update guestSpeakers array to match the new count
      setGuestSpeakers(prevGuests => {
        const newGuests = [...prevGuests];
        while (newGuests.length < count) { // No -1 anymore since we track all speakers
          newGuests.push(false);
        }
        return newGuests.slice(0, count);
      });
    };

    const handleGuestChange = (index) => {
      const newGuestSpeakers = [...guestSpeakers];
      newGuestSpeakers[index] = !newGuestSpeakers[index]; // Toggle the current speaker's guest status
      
      // If this speaker was just marked as a guest, unmark any other speakers
      if (newGuestSpeakers[index]) {
        for (let i = 0; i < newGuestSpeakers.length; i++) {
          if (i !== index) {
            newGuestSpeakers[i] = false;
          }
        }
      }
      
      setGuestSpeakers(newGuestSpeakers);
    };

    const getSpeakerCountLabel = (count) => {
      if (count === 3 || count === 4) {
        return `Number of Speakers: ${count} (In Beta)`;
      }
      return `Number of Speakers: ${count}`;
    };

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="modal-close" onClick={onClose}>&times;</button>
          <h2>Name Your Project and Speakers</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Enter project name"
              required
            />
            <div className="speaker-count-slider">
              <label htmlFor="speakerCount">{getSpeakerCountLabel(speakerCount)}</label>
              <input
                type="range"
                id="speakerCount"
                min="1"
                max="4"
                value={speakerCount}
                onChange={handleSpeakerCountChange}
              />
            </div>
            
            {/* All speakers with guest option */}
            {Array.from({ length: speakerCount }, (_, i) => i).map(index => (
              <div key={index} className="speaker-input-container">
                <div className="guest-checkbox-container">
                  <label htmlFor={`isGuest-${index}`}>Guest?</label>
                  <input
                    type="checkbox"
                    id={`isGuest-${index}`}
                    checked={guestSpeakers[index]}
                    onChange={() => handleGuestChange(index)}
                  />
                </div>
                <input
                  type="text"
                  value={speakerNames[index] || ''}
                  onChange={(e) => handleSpeakerNameChange(index, e.target.value)}
                  placeholder={`Enter Speaker ${index + 1} name (In Order of Appearance)`}
                />
              </div>
            ))}
            
            <button type="submit" className="modal-submit">Start Project</button>
          </form>
        </div>
      </div>
    );
  }

  // Move this component outside of any function, before the GenerateContent function
  const PostNavigator = ({ posts, currentIndex, setCurrentIndex, type }) => {
    const totalPosts = posts.length;
    
    const handlePrev = () => {
      setCurrentIndex((prev) => (prev > 0 ? prev - 1 : totalPosts - 1));
    };
    
    const handleNext = () => {
      setCurrentIndex((prev) => (prev < totalPosts - 1 ? prev + 1 : 0));
    };
    
    return (
      <div className="post-navigator">
        <div className="post-content">
          <pre className="preformatted-text">{posts[currentIndex]}</pre>
        </div>
        <div className="navigation-controls">
          <button 
            className="nav-button" 
            onClick={handlePrev}
            disabled={totalPosts <= 1}
          >
            &#8592;
          </button>
          <span className="post-indicator">{currentIndex + 1}/{totalPosts}</span>
          <button 
            className="nav-button" 
            onClick={handleNext}
            disabled={totalPosts <= 1}
          >
            &#8594;
          </button>
        </div>
      </div>
    );
  };

  function GenerateContent({ onNavigate, initialProjectName, initialSpeakerNames, initialGuestName, setUsage, planType }) {
    const [file, setFile] = useState(null);
    const [projectName, setProjectName] = useState(initialProjectName || '');
    const [speakerNames, setSpeakerNames] = useState(initialSpeakerNames || ['', '']);
    // eslint-disable-next-line no-unused-vars
    const [guestName, setGuestName] = useState(initialGuestName || null);
    const [guestBio, setGuestBio] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState('');
    const [showNotes, setShowNotes] = useState('');
    const [chapters, setChapters] = useState([]);
    const [titles, setTitles] = useState('');
    // const [keywords, setKeywords] = useState('');
    // const [blogPost, setBlogPost] = useState('');
    // const [newsletterPost, setNewsletterPost] = useState('');
    // const [socialPost, setSocialPost] = useState('');
    const [tweets, setTweets] = useState('');
    // const [quotes, setQuotes] = useState('');
    // const [bulletPoints, setBulletPoints] = useState('');
    const [error, setError] = useState('');
    const [currentTweetIndex, setCurrentTweetIndex] = useState(0);
    // const [currentSocialPostIndex, setCurrentSocialPostIndex] = useState(0);
    const [parsedTweets, setParsedTweets] = useState([]);
    // const [parsedSocialPosts, setParsedSocialPosts] = useState([]);
    const [linkedinPost, setLinkedinPost] = useState('');
    const [facebookPost, setFacebookPost] = useState('');
    const [currentLinkedinPostIndex, setCurrentLinkedinPostIndex] = useState(0);
    const [currentFacebookPostIndex, setCurrentFacebookPostIndex] = useState(0);
    const [parsedLinkedinPosts, setParsedLinkedinPosts] = useState([]);
    const [parsedFacebookPosts, setParsedFacebookPosts] = useState([]);
    const [blogPost, setBlogPost] = useState('');
    const [newsletterPost, setNewsletterPost] = useState('');
    const [keywords, setKeywords] = useState('');

  
    useEffect(() => {
      if (initialProjectName) {
        setProjectName(initialProjectName);
      }
      if (initialSpeakerNames && initialSpeakerNames.length > 0) {
        setSpeakerNames(initialSpeakerNames);
      }
    }, [initialProjectName, initialSpeakerNames]);
  
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        // const fileSizeInMB = file.size / (1024 * 1024);
        // const maxSizeInMB = planType === 'free' ? 2 : 4;
      
        // if (fileSizeInMB > maxSizeInMB) {
        //   setError(`File size exceeds the ${maxSizeInMB}GB limit for your plan.`);
        //   return;
        // }
        setFile(file);
      };
  
      const handleSubmit = async () => {
        if (!file) {
          setError('Please select an audio file');
          return;
        }
        if (!projectName || speakerNames.length === 0 || speakerNames.some(name => !name.trim())) {
          setError('Please provide project name and all speaker names');
          return;
        }
      
        setIsLoading(true);
        setError('');
      
        const formData = new FormData();
        formData.append('audio', file);
        speakerNames.forEach((name, index) => {
          formData.append(`speaker${index + 1}`, name);
        });
      
        // Add guest name to form data if available
        if (guestName) {
          console.log('Using guest name:', guestName);
          formData.append('guestName', guestName);
        }
      
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) {
            throw new Error('User not found');
          }
      
          formData.append('userId', user.id);
      
          const { data: subscriptionData, error: subscriptionError } = await supabase
            .from('subscriptions')
            .select('plan_type, free_plan_used, usage, upload_count')
            .eq('user_id', user.id)
            .single();
      
          if (subscriptionError) throw subscriptionError;
      
          if (subscriptionData.plan_type === 'free') {
            if (subscriptionData.upload_count >= 1) {
              setError('You have already used your free plan upload. Please upgrade to continue.');
              setIsLoading(false);
              return;
            }
          }
      
          // Debug the audio duration
          const fileDurationInSeconds = await getAudioDuration(file);
          console.log('Audio duration in seconds:', fileDurationInSeconds);
          const fileDurationInMinutes = fileDurationInSeconds / 60;
          console.log('Audio duration in minutes:', fileDurationInMinutes);

    
      console.log('Sending to Modal (If in Dev mode this means file > 60 seconds):');
      console.log('File name:', file.name);
      console.log('File size:', file.size, 'bytes');
      console.log('File type:', file.type);
      console.log('Project name:', projectName);
      console.log('Speaker names:', speakerNames);
      console.log('User ID:', user.id);
      console.log('Subscription data:', subscriptionData);
    
      
          let newUsage = 0;
          let newUploadCount = 0;
      
          if (subscriptionData.plan_type === 'free') {
            newUsage = Math.min(60, fileDurationInMinutes);
            newUploadCount = 1;
          } else {
            const planLimits = {
              starter: 240,
              pro: 600
            };
            const currentUsage = parseFloat(subscriptionData.usage) || 0;
            newUsage = Math.min(currentUsage + fileDurationInMinutes, planLimits[subscriptionData.plan_type] || Infinity);
            newUploadCount = (subscriptionData.upload_count || 0) + 1;
          }
      
          // Round newUsage to 2 decimal places
          newUsage = Math.round(newUsage * 100) / 100;
      
          console.log('Calculated usage:', newUsage);
      
          // Update the subscription data in Supabase
          const { error: updateError } = await supabase
            .from('subscriptions')
            .update({ 
              usage: newUsage, 
              upload_count: newUploadCount 
            })
            .eq('user_id', user.id);
      
          if (updateError) {
            console.error('Error updating subscription data:', updateError);
            throw updateError;
          }
      
        //   // Continue with the rest of the submission process...
        //   const response = await axios.post(`${process.env.NODE_ENV === 'production' ? process.env.PROD_CONTENT_SERVICE_URL : process.env.REACT_APP_API_URL}/transcribe`, formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //     },
        //   });

        const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_CONTENT_SERVICE_URL : process.env.REACT_APP_API_URL;
        const response = await axios.post(`${baseUrl}/transcribe`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          });
      
          console.log('Transcribe response:', response.data);
          let transcriptData = response.data.transcript || 'No transcript available';
          
          speakerNames.forEach((name, index) => {
            const speakerRegex = new RegExp(`Speaker ${index + 1}:`, 'g');
            transcriptData = transcriptData.replace(speakerRegex, `${name}:`);
          });
                
          const chaptersData = response.data.chapters || [];
      
          const showNotesResponse = await axios.post(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_CONTENT_SERVICE_URL : process.env.REACT_APP_API_URL}/generate-show-notes`, {
            transcript: transcriptData,
          });
          console.log('Show notes response:', showNotesResponse.data);
          const showNotesData = showNotesResponse.data.showNotes || 'No show notes available';
          const titlesData = showNotesResponse.data.titles || 'No titles available';
      
          const contentResponse = await axios.post(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_CONTENT_SERVICE_URL : process.env.REACT_APP_API_URL}/generate-content`, {
            transcript: transcriptData,
          });
          console.log('Content response:', contentResponse.data);
          const blogPostData = contentResponse.data.blogPost || 'No blog post available';
          const newsletterPostData = contentResponse.data.newsletterPost || 'No newsletter post available';
          const keywordsData = contentResponse.data.keywords || 'No keywords available';
        //   const bulletPointsData = contentResponse.data.bulletPoints || 'No bullet points available';
      
          // After receiving the contentResponse
          console.log('Full content response:', contentResponse.data);
          console.log('LinkedIn post from API:', contentResponse.data.linkedinPost);
          console.log('Facebook post from API:', contentResponse.data.facebookPost);
      
          // Create a content object to store all generated content
          const linkedinPostData = contentResponse.data.linkedinPost || 'No LinkedIn post available';
          const facebookPostData = contentResponse.data.facebookPost || 'No Facebook post available';
          const tweetData = contentResponse.data.tweets || 'No tweets available';
      
          // Log what we're about to set in state
          console.log('Setting LinkedIn post state to:', linkedinPostData);
          console.log('Setting Facebook post state to:', facebookPostData);
      
          // Set the state for these posts
          setLinkedinPost(linkedinPostData);
          setFacebookPost(facebookPostData);
          setTweets(tweetData);
          setBlogPost(blogPostData);
          setNewsletterPost(newsletterPostData);
          setKeywords(keywordsData);
        //   setBulletPoints(bulletPointsData);
      
          // Add guestName to content object
          const content = {
            summary: transcriptData,
            showNotes: showNotesData,
            chapters: chaptersData,
            titles: titlesData,
            keywords: keywordsData,
            blogPost: blogPostData,
            newsletterPost: newsletterPostData,
            linkedinPost: linkedinPostData,
            facebookPost: facebookPostData,
            tweets: tweetData,
            // bulletPoints: bulletPointsData,
            audioDuration: fileDurationInSeconds,
            guestName: guestName
          };
      
          // Generate guest bio if guest name is provided
          if (guestName) {
            console.log('Generating bio for guest:', guestName);
            try {
                const guestBioResponse = await axios.post(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_CONTENT_SERVICE_URL : process.env.REACT_APP_API_URL}/generate-guest-bio`, {
                    guestName: guestName
                });
                // Clean any potential formatting from the response
                const guestBioText = (guestBioResponse.data.guestBio || `${guestName} is a distinguished guest on this podcast.`).replace(/\*\*/g, '').replace(/\*/g, '');
                setGuestBio(guestBioText);
                content.guestBio = guestBioText;
            } catch (error) {
                console.error('Error generating guest bio:', error);
                const fallbackBio = `${guestName}\n\nGuest bio information unavailable.`;
                setGuestBio(fallbackBio);
                content.guestBio = fallbackBio;
            }
          }
      
          // Before saving, ensure guest_bio is a string
          if (content.guestBio && typeof content.guestBio !== 'string') {
            content.guestBio = JSON.stringify(content.guestBio);
          }
      
          // Or if it might be null
          if (!content.guestBio) {
            content.guestBio = '';
          }
      
          // Add guestName to content object
          content.guestName = guestName;
      
          await saveProject(content);
      
          setSummary(transcriptData);
          setChapters(chaptersData);
          setShowNotes(showNotesData);
          setTitles(titlesData);
          setKeywords(contentResponse.data.keywords || 'No keywords available');
          setBlogPost(blogPostData);
          setNewsletterPost(newsletterPostData);
    
          setTweets(contentResponse.data.tweets || 'No tweets available');
        //   setQuotes(quotesData);
          // setBulletPoints(bulletPointsData);
      
        } catch (error) {
          console.error('Error fetching transcript or show notes:', error);
          setError('Error fetching transcript or show notes. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      };
      
      const saveProject = async (content) => {
        if (!projectName.trim()) {
          alert("Please enter a project name before submitting.");
          return;
        }
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          alert("You must be logged in to save a project.");
          return;
        }
      
        console.log('Saving project with the following content:');
        console.log('Summary:', content.summary);
        console.log('Show Notes:', content.showNotes);
        console.log('Chapters:', content.chapters);
        console.log('Titles:', content.titles);
        console.log('Keywords:', content.keywords);
        console.log('Blog Post:', content.blogPost);
        console.log('Newsletter Post:', content.newsletterPost);
        console.log('Social Post:', content.socialPost);
        console.log('Tweets:', content.tweets);
        // console.log('Bullet Points:', content.bulletPoints);
        console.log('Guest Bio:', content.guestBio); // Log guest bio
        console.log('Audio Duration:', content.audioDuration);
      
        const project = {
          name: projectName,
          user_id: user.id,
          content: content // This includes all content including guest bio
        };
      
        console.log('Project to be saved:', project);
      
        try {
            const { data, error } = await supabase
              .from('projects')
              .insert([project]);
        
            if (error) {
              console.error('Error saving project:', error);
              throw error;
            }
        
            console.log('Project saved successfully:', data);
            alert('Project saved successfully!');
          } catch (error) {
            console.error('Error saving project:', error);
            alert('Failed to save project. Please try again.');
          }
        };

        const formatTranscript = (transcript) => {
            if (!transcript) return 'No transcript available';
            const lines = transcript.split('\n');
            return lines.map((line, index) => {
              const speakerMatch = line.match(/^([^:]+):/);
              if (speakerMatch) {
                const speakerName = speakerMatch[1];
                const content = line.slice(speakerMatch[0].length).trim();
                return (
                  <div key={index}>
                    <strong>{speakerName}:</strong> {content}
                  </div>
                );
              }
              return <div key={index}>{line}</div>;
            });
          };

    const formatChapters = (chapters) => {
        if (!chapters || chapters.length === 0) {
            return 'No chapters available';
        }
    
        return chapters.map((chapter, index) => (
            <div key={index}>
                <strong>{chapter.name}</strong>: {formatTime(chapter.start)} 
                <br />
                {chapter.summary}
                <br /><br />
            </div>
        ));
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const parseTweets = (tweetText) => {
      if (!tweetText) return ['No tweets available'];
      
      // Match content between "Tweet 1:", "Tweet 2:", etc.
      const tweetRegex = /Tweet \d+:([\s\S]*?)(?=Tweet \d+:|$)/g;
      const matches = [...tweetText.matchAll(tweetRegex)];
      
      if (matches && matches.length > 0) {
        return matches.map(match => match[1].trim());
      }
      
      // Fallback to paragraph splitting if no tweet markers found
      return tweetText.split('\n\n').filter(p => p.trim());
    };

    const parseSocialPosts = (socialPostText) => {
      if (!socialPostText) return ['No social post available'];
      
      // For now, we'll create 3 posts from the single post by splitting it
      // In the future, you might want to generate multiple posts from the backend ** change **
      const paragraphs = socialPostText.split('\n\n').filter(p => p.trim());
      
      if (paragraphs.length <= 1) {
        // If there's only one paragraph, create 3 variations
        return [
          socialPostText,
          `Looking for insights on this topic? ${socialPostText.split('.')[0]}.`,
          `Don't miss out! ${socialPostText}`
        ];
      }
      
      // Otherwise use the paragraphs as separate posts
      return paragraphs;
    };
  
    useEffect(() => {
      // Debug the actual data we're receiving
      console.log('LinkedIn post state:', linkedinPost);
      console.log('Facebook post state:', facebookPost);
      console.log('Tweets state:', tweets);

      if (linkedinPost) {
        const parsed = parseLinkedinPosts(linkedinPost);
        console.log('Parsed LinkedIn posts:', parsed);
        setParsedLinkedinPosts(parsed);
      } else {
        // If we're getting here, the API might not be returning data properly
        console.log('LinkedIn post is empty - API may not be returning data correctly');
      }
      
      if (facebookPost) {
        const parsed = parseFacebookPosts(facebookPost);
        console.log('Parsed Facebook posts:', parsed);
        setParsedFacebookPosts(parsed);
      } else {
        console.log('Facebook post is empty - API may not be returning data correctly');
      }
      
      if (tweets) {
        const parsed = parseTweets(tweets);
        console.log('Parsed tweets:', parsed);
        setParsedTweets(parsed);
      }
    }, [linkedinPost, facebookPost, tweets]);

    // Add this new function to format the combined show notes
    const formatCombinedShowNotes = (summary, chapters, guestBio, guestName) => {
      let showNotesContent = '';
      
      // Add Podcast Summary section
      showNotesContent += `${summary || 'No summary available'}\n\n\n`;
      
      // Add Guest Bio section if guest exists
      if (guestName && guestBio) {
        showNotesContent += `Guest Bio: ${guestName}\n\n${guestBio}\n\n\n`;
      }
      
      // Add Timestamped Chapters section
      showNotesContent += `Chapters:\n\n`;
      
      if (!chapters || chapters.length === 0) {
        showNotesContent += 'No chapters available\n';
      } else {
        chapters.forEach((chapter, index) => {
          const startTime = formatTime(chapter.start);
          showNotesContent += `${chapter.name}: ${startTime}\n`;
        });
      }
      
      return showNotesContent + '\n\nLinks:\n\n(CTA and links to social media platforms and products here if applicable - make sure to delete/replace this before publishing)' + '\n\n\nTranscript:\n\n(Copy paste transcript or its link here - make sure to delete/replace this before publishing)';
    };

    const boxes = [
        { id: 1, header: 'Full Transcription', text: formatTranscript(summary) || 'No transcript available' },
        { id: 4, header: 'Titles', text: titles || 'No titles available' },
        { id: 5, header: 'Keywords', text: keywords || 'No keywords available' },
        { id: 6, header: 'Blog Post', text: formatBlogPost(blogPost) || 'No blog post available' },
        { id: 7, header: 'Newsletter Post', text: newsletterPost || 'No newsletter post available' },
        { 
          id: 8, 
          header: 'LinkedIn & Facebook Post', 
          text: parsedLinkedinPosts.length > 0 ? 
            <PostNavigator 
              posts={parsedLinkedinPosts} 
              currentIndex={currentLinkedinPostIndex} 
              setCurrentIndex={setCurrentLinkedinPostIndex} 
              type="linkedin" 
            /> : 'No LinkedIn post available' 
        },
        { 
          id: 9, 
          header: 'Tweets', 
          text: parsedTweets.length > 0 ? 
            <PostNavigator 
              posts={parsedTweets} 
              currentIndex={currentTweetIndex} 
              setCurrentIndex={setCurrentTweetIndex} 
              type="tweet" 
            /> : 'No tweets available' 
        },
        { 
          id: 10, 
          header: 'Complete Show Notes (Summary, Chapters, Guest Bio)', 
          text: formatCombinedShowNotes(showNotes, chapters, guestBio, guestName),
          isFullSize: true
        }
      ];
    
      return (
        <div className="generate-content">
          <button className="back-arrow" onClick={() => onNavigate('menu')}>&#8592; Back</button>
          <h1>{projectName ? `Let's create content for ${projectName}!` : "Let's create content for your podcast!"}</h1>
          <p>Project: {projectName}</p>
          {speakerNames.map((name, index) => (
            <p key={index}>Speaker {index + 1}: {name}</p>
          ))}
          <div className="file-upload-container">
            <input type="file" id="file-input" onChange={handleFileChange} accept="audio/mpeg, .mp3" />
            <label htmlFor="file-input" className="file-upload-label">Upload Audio File (.mp3)</label>
            <button className="button-9" onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? 'Processing...' : 'Submit'}
            </button>
          </div>
          {error && <div className="error-message">{error}</div>}
    
          <div className="grid-container">
          {boxes.map((box) => (
  <div key={box.id} className={`grid-item ${box.id === 1 ? 'full-transcription' : ''} ${box.isFullSize ? 'show-notes-full' : ''}`}>
    <h3>{box.header}</h3>
    <div className="box-content">
      {typeof box.text === 'string' ? (
        <pre className="preformatted-text">{box.text}</pre>
      ) : (
        box.text
      )}
    </div>
    <div className="box-footer">
      <button className="button-9" onClick={() => {
        const content = typeof box.text === 'string' ? 
          box.text : 
          (box.id === 8 ? parsedLinkedinPosts[currentLinkedinPostIndex] : 
            box.id === 9 ? parsedTweets[currentTweetIndex] : '');
        exportAsPDF(content, `${projectName}_${box.header}`);
      }}>Export as PDF</button>
      <button className="button-9" onClick={() => {
        const content = typeof box.text === 'string' ? 
          box.text : 
          (box.id === 8 ? parsedLinkedinPosts[currentLinkedinPostIndex] : 
            box.id === 9 ? parsedTweets[currentTweetIndex] : '');
        exportAsTXT(content, `${projectName}_${box.header}`);
      }}>Export as TXT</button>
      {box.id === 1 && (
        <button className="button-9" onClick={() => exportAsSRT(box.text, `${projectName}_${box.header}`, 0)}>Export as SRT</button>
      )}
    </div>
  </div>
))}
          </div>
        </div>
      );
    }

    function SavedProjects({ onNavigate, planType }) {
        const [savedProjects, setSavedProjects] = useState([]);
        const [selectedProject, setSelectedProject] = useState(null);
        const [currentTweetIndex, setCurrentTweetIndex] = useState(0);
        const [currentLinkedinPostIndex, setCurrentLinkedinPostIndex] = useState(0);
        const [currentFacebookPostIndex, setCurrentFacebookPostIndex] = useState(0);
        
        const planLimits = {
            free: 1,
            starter: 10,
            pro: 20
        };
    
        const projectLimit = planLimits[planType] || 0;
    
        useEffect(() => {
            const fetchProjects = async () => {
              const { data: { user } } = await supabase.auth.getUser();
              if (user) {
                const { data: subscriptionData, error: subscriptionError } = await supabase
                  .from('subscriptions')
                  .select('plan_type')
                  .eq('user_id', user.id)
                  .single();
          
                if (subscriptionError) {
                  console.error('Error fetching subscription data:', subscriptionError);
                  return;
                }
          
                const limit = subscriptionData.plan_type === 'free' ? 1 : projectLimit;
          
                const { data, error } = await supabase
                  .from('projects')
                  .select('*')
                  .eq('user_id', user.id)
                  .order('created_at', { ascending: false })
                  .limit(limit);
          
                if (error) {
                  console.error('Error fetching projects:', error);
                } else {
                  console.log('Loaded saved projects:', data);
                  setSavedProjects(data);
                }
              }
            };
          
            fetchProjects();
          }, [planType, projectLimit]);
      
        const handleProjectClick = (project) => {
          setSelectedProject(project);
        };
      
        const handleBack = () => {
          if (selectedProject) {
            setSelectedProject(null);
          } else {
            onNavigate('menu');
          }
        };
      
        const handleDeleteProject = async (projectId) => {
            try {
              const { data: { user } } = await supabase.auth.getUser();
              if (user) {
                const { error } = await supabase
                  .from('projects')
                  .delete()
                  .eq('id', projectId)
                  .eq('user_id', user.id);
          
                if (error) throw error;
          
                setSavedProjects(savedProjects.filter(project => project.id !== projectId));
              }
            } catch (error) {
              console.error('Error deleting project:', error);
              alert('Failed to delete project. Please try again.');
            }
          };
      
          const formatContent = (content) => {
            console.log('Formatting content:', content);
            if (content === null || content === undefined) {
              console.log('Content is null or undefined');
              return 'No content available';
            } else if (Array.isArray(content)) {
              console.log('Content is an array of length:', content.length);
              if (content.length > 0 && content[0].hasOwnProperty('start')) {
                console.log('Content appears to be chapters');
                return content.map(chapter => 
                  `${chapter.name}: ${formatTime(chapter.start)} - ${formatTime(chapter.end)}\n${chapter.summary}`
                ).join('\n\n');
              }
              return content.length > 0 ? content.join('\n') : 'No content available';
            } else if (typeof content === 'object') {
              console.log('Content is an object');
              return Object.keys(content).length > 0 ? JSON.stringify(content, null, 2) : 'No content available';
            } else if (typeof content === 'string') {
              console.log('Content is a string of length:', content.length);
              return content.trim() || 'No content available';
            }
            console.log('Content is of unexpected type:', typeof content);
            return String(content) || 'No content available';
          };
          
        const formatTime = (seconds) => {
          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = Math.floor(seconds % 60);
          return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
        };
      
        const parseTweets = (tweetText) => {
          if (!tweetText) return ['No tweets available'];
          
          // Match content between "Tweet 1:", "Tweet 2:", etc.
          const tweetRegex = /Tweet \d+:([\s\S]*?)(?=Tweet \d+:|$)/g;
          const matches = [...tweetText.matchAll(tweetRegex)];
          
          if (matches && matches.length > 0) {
            return matches.map(match => match[1].trim());
          }
          
          // Fallback to paragraph splitting if no tweet markers found
          return tweetText.split('\n\n').filter(p => p.trim());
        };

        const parseSocialPosts = (socialPostText) => {
          if (!socialPostText) return ['No social post available'];
          
          const paragraphs = socialPostText.split('\n\n').filter(p => p.trim());
          
          if (paragraphs.length <= 1) {
            return [
              socialPostText,
              `Looking for insights on this topic? ${socialPostText.split('.')[0]}.`,
              `Don't miss out! ${socialPostText}`
            ];
          }
          
          return paragraphs;
        };
      
        const formatCombinedShowNotes = (project) => {
          if (!project || !project.content) return 'No show notes available';
          
          let showNotesContent = '';
          
          // Add Podcast Summary section
          showNotesContent += `Podcast Summary: \n\n${formatContent(project.content.showNotes) || 'No summary available'}\n\n`;
          
          // Add Guest Bio section if guest exists
          if (project.content.guestBio) {
            showNotesContent += `Guest Bio (${project.content.guestName}): \n\n${formatContent(project.content.guestBio)}\n\n`;
          }
          
          // Add Timestamped Chapters section
          showNotesContent += `Chapters: \n\n`;
          
          if (!project.content.chapters || project.content.chapters.length === 0) {
            showNotesContent += 'No chapters available\n';
          } else {
            project.content.chapters.forEach((chapter) => {
              const startTime = formatTime(chapter.start);
            //   const endTime = formatTime(chapter.end);
              showNotesContent += `${chapter.name}: ${startTime}\n\n`;
            });
          }
          
          return showNotesContent;
        };

        const boxes = [
          { id: 1, header: 'Full Transcription', key: 'summary' },
          { id: 4, header: 'Titles', key: 'titles' },
          { id: 5, header: 'Keywords', key: 'keywords' },
          { id: 6, header: 'Blog Post', key: 'blogPost' },
          { id: 7, header: 'Newsletter Post', key: 'newsletterPost' },
          { id: 8, header: 'LinkedIn & Facebook Post', key: 'linkedinPost', isMultiple: true, type: 'linkedin' },
          { id: 9, header: 'Tweets', key: 'tweets', isMultiple: true, type: 'tweet' },
          { id: 10, header: 'Complete Show Notes (Podcast Summary, Timestamped Chapters, Guest Bio)', key: 'combinedShowNotes', isFullSize: true, customFormatter: formatCombinedShowNotes }
        ];
      
        if (selectedProject) {
            return (
              <div className="saved-project-details">
                <button className="back-arrow" onClick={handleBack}>&#8592; Back</button>
                <h1>{selectedProject.name}</h1>
                <div className="grid-container">
                {boxes.map((box) => (
  <div key={box.id} className={`grid-item ${box.isFullSize ? 'show-notes-full' : ''}`}>
    <h3>{box.header}</h3>
    <div className="box-content">
      {box.isMultiple ? (
        <PostNavigator 
          posts={box.type === 'tweet' 
            ? parseTweets(selectedProject.content[box.key]) 
            : box.type === 'linkedin'
              ? parseLinkedinPosts(selectedProject.content[box.key])
              : parseFacebookPosts(selectedProject.content[box.key])}
          currentIndex={
            box.type === 'tweet' 
              ? currentTweetIndex 
              : box.type === 'linkedin' 
                ? currentLinkedinPostIndex 
                : currentFacebookPostIndex
          }
          setCurrentIndex={
            box.type === 'tweet' 
              ? setCurrentTweetIndex 
              : box.type === 'linkedin' 
                ? setCurrentLinkedinPostIndex 
                : setCurrentFacebookPostIndex
          }
          type={box.type}
        />
      ) : (
        <pre className="preformatted-text">
          {box.customFormatter ? box.customFormatter(selectedProject) : formatContent(selectedProject.content[box.key])}
        </pre>
      )}
    </div>
    <div className="box-footer">
      <button className="button-9" onClick={() => {
        const content = box.isMultiple 
          ? (box.type === 'tweet' 
              ? parseTweets(selectedProject.content[box.key])[currentTweetIndex]
              : box.type === 'linkedin'
                ? parseLinkedinPosts(selectedProject.content[box.key])[currentLinkedinPostIndex]
                : parseFacebookPosts(selectedProject.content[box.key])[currentFacebookPostIndex])
          : (box.customFormatter ? box.customFormatter(selectedProject) : formatContent(selectedProject.content[box.key]));
        exportAsPDF(content, `${selectedProject.name}_${box.header}`);
      }}>Export as PDF</button>
      <button className="button-9" onClick={() => {
        const content = box.isMultiple 
          ? (box.type === 'tweet' 
              ? parseTweets(selectedProject.content[box.key])[currentTweetIndex]
              : box.type === 'linkedin'
                ? parseLinkedinPosts(selectedProject.content[box.key])[currentLinkedinPostIndex]
                : parseFacebookPosts(selectedProject.content[box.key])[currentFacebookPostIndex])
          : (box.customFormatter ? box.customFormatter(selectedProject) : formatContent(selectedProject.content[box.key]));
        exportAsTXT(content, `${selectedProject.name}_${box.header}`);
      }}>Export as TXT</button>
      {box.id === 1 && (
        <button className="button-9" onClick={() => exportAsSRT(formatContent(selectedProject.content[box.key]), `${selectedProject.name}_${box.header}`, selectedProject.content.audioDuration)}>Export as SRT</button>
      )}
    </div>
  </div>
))}
                </div>
              </div>
            );
          }
      
          return (
            <div className="saved-projects">
              <button className="back-arrow" onClick={handleBack}>&#8592; Back</button>
              <h1>Saved Projects ({savedProjects.length}/{projectLimit})</h1>
              <ul>
              {savedProjects.map((project) => (
                <li key={project.id} className="clickable-project" onClick={() => handleProjectClick(project)}>
                  <span>{project.name || "Untitled Project"}</span>
                  <button onClick={(e) => { e.stopPropagation(); handleDeleteProject(project.id); }} className="delete-button">Delete</button>
                </li>
              ))}
              </ul>
            </div>
          );
      }

      function UsageCounter({ usage, upload_count, planType }) {
        if (!planType) {
          return (
            <div className="usage-counter">
              No plan
            </div>
          );
        }
      
        if (planType === 'free') {
          return (
            <div className="usage-counter">
              {upload_count === 0 ? '1 Upload Left (60 minutes left)' : 'No uploads left'}
            </div>
          );
        }
      
        const planLimits = {
          free: 60,
          starter: 240,
          pro: 600
        };
        const limit = planLimits[planType] || 0;
        const usageDisplay = Math.round(usage * 100) / 100; // Round to 2 decimal places
        return (
          <div className="usage-counter">
            {usage === 0 ? `0 / ${limit} minutes used` : `${usageDisplay} / ${limit} minutes used`}
          </div>
        );
      }

function App() {
    const [session, setSession] = useState(null);
    const [currentPage, setCurrentPage] = useState('menu');
    const [projectName, setProjectName] = useState('');
    const [speakerNames, setSpeakerNames] = useState([]);
    const [guestName, setGuestName] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState('inactive');
    const [usage, setUsage] = useState(0);
    const [planType, setUserPlan] = useState('');
    const [upload_count, setUploadCount] = useState(0);
  
    const checkSubscriptionStatus = useCallback(async (userId) => {
        console.log('Checking subscription status for user:', userId);
        
        try {
          const { data, error } = await supabase
            .from('subscriptions')
            .select('status, usage, upload_count, current_period_end, auto_renew, plan_type, free_plan_used')
            .eq('user_id', userId)
            .single();
          
          if (error) {
            console.error('Supabase subscription check error:', error);
            console.error('Error details:', error.message, error.details, error.hint);
            throw error;
          }
      
          if (data) {
            const newStatus = data.status === 'active' || data.status === 'cancel_pending' ? 'active' : 'inactive';
            const newPlanType = data.plan_type || 'free';
            const newUsage = data.usage || 0;
            
            console.log('Subscription data:', data);
            console.log('Setting subscription status:', newStatus);
            console.log('Setting plan type:', newPlanType);
            console.log('Setting usage:', newUsage);
          
            setSubscriptionStatus(newStatus);
            setUserPlan(newPlanType);
            setUsage(newUsage);
            setUploadCount(data.upload_count || 0);
          } else {
            console.log('No subscription data found for user');
            setSubscriptionStatus('inactive');
            setUsage(0);
            setUploadCount(0);
            setUserPlan('');
          }
        } catch (error) {
          console.error('Unexpected error during subscription check:', error);
          setSubscriptionStatus('inactive');
          setUsage(0);
          setUploadCount(0);
          setUserPlan('');
        }
      }, []);

    // ... rest of the useEffect and other functions remain the same
  
    useEffect(() => {
      const checkSession = async () => {
        const { data: { session } } = await supabase.auth.getSession();
        setSession(session);
        if (session) {
          checkSubscriptionStatus(session.user.id);
          if (!currentPage) {
            setCurrentPage('menu');
          }
        }
      };
  
      checkSession();

  const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
    setSession(session);
    if (session) {
      checkSubscriptionStatus(session.user.id);
      // Only set to 'menu' if there's no current page
      if (!currentPage) {
        setCurrentPage('menu');
      }
    } else {
      setCurrentPage('auth');
    }
    
  });
  return () => subscription.unsubscribe();
}, [checkSubscriptionStatus, currentPage]);
  
    useEffect(() => {
      console.log('Current subscription status:', subscriptionStatus);
    }, [subscriptionStatus]);
  
    const navigateTo = (page) => {
      setCurrentPage(page);
    };
  
    // const handleManageSubscription = async () => {
    //   try {
    //     console.log('Starting subscription management process');
    //     const { data: { user } } = await supabase.auth.getUser();
    //     if (!user) {
    //       throw new Error('User not found');
    //     }
    //     console.log('User found:', user.id);
    
    //     // Instead of redirecting to Stripe, we'll navigate to our subscription management page
    //     navigateTo('subscription');
    //   } catch (error) {
    //     console.error('Error managing subscription:', error);
    //     alert(`Failed to manage subscription. Error: ${error.message}`);
    //   }
    // };
  
    const handleSignOut = async () => {
      await supabase.auth.signOut()
      localStorage.removeItem('subscriptionStatus')
      window.location.href = process.env.REACT_APP_SIGN_OUT_REDIRECT_URL;
    }
  
    const handleGenerateContent = (name, speakers, guest) => {
      setProjectName(name);
      setSpeakerNames(speakers);
      setGuestName(guest);
      navigateTo('generate');
    };

    const handleCancelSubscription = async () => {
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) {
            throw new Error('User not found');
          }
          const response = await fetch(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_CONTENT_SERVICE_URL : process.env.REACT_APP_API_URL}/cancel-subscription`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: user.id,
            }),
          });
      
          if (!response.ok) {
            throw new Error('Failed to cancel subscription');
          }
      
          const result = await response.json();
          console.log('Subscription cancellation result:', result);
      
          // Update the subscription status
          setSubscriptionStatus('cancel_pending');
          alert('Your subscription has been scheduled for cancellation at the end of the current billing period.');
          
          // Refresh the subscription status
          checkSubscriptionStatus(user.id);
        } catch (error) {
          console.error('Error cancelling subscription:', error);
          alert(`Failed to cancel subscription. Error: ${error.message}`);
        }
      };
  
    return (
        <div className="App">
          {!session ? (
            <Auth setSubscriptionStatus={setSubscriptionStatus} onSignIn={() => setCurrentPage('menu')} />
          ) : (
            <>
  <UsageCounter upload_count={upload_count} usage={usage} planType={planType} />
  {currentPage === 'menu' && (
    <Menu 
      onNavigate={navigateTo} 
      onGenerateContent={handleGenerateContent} 
      subscriptionStatus={subscriptionStatus}
      handleSignOut={handleSignOut}
      planType={planType}
    />
  )}
  {currentPage === 'generate' && (subscriptionStatus === 'active' || subscriptionStatus === 'cancel_pending') && (
            <GenerateContent
              onNavigate={navigateTo}
              initialProjectName={projectName}
              initialSpeakerNames={speakerNames}
              initialGuestName={guestName}
              setUsage={setUsage}
              planType={planType}
            />
          )}
              {currentPage === 'saved' && (subscriptionStatus === 'active' || subscriptionStatus === 'cancel_pending') && (
                <SavedProjects onNavigate={navigateTo} planType={planType} />
              )}
              {currentPage === 'subscription' && (
  <SubscriptionManagement
    onNavigate={navigateTo}
    subscriptionStatus={subscriptionStatus}
    setSubscriptionStatus={setSubscriptionStatus}
    checkSubscriptionStatus={checkSubscriptionStatus}
    handleCancelSubscription={handleCancelSubscription}
  />
)}
            </>
          )}
        </div>
      );
  }

  function SubscriptionManagement({ onNavigate, subscriptionStatus, setSubscriptionStatus, checkSubscriptionStatus, handleCancelSubscription }) {
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [planType, setUserPlan] = useState('');
    const [freePlanUsed, setFreePlanUsed] = useState(false);
  
    useEffect(() => {
      const fetchUserPlanInfo = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const { data, error } = await supabase
            .from('subscriptions')
            .select('plan_type, free_plan_used')
            .eq('user_id', user.id)
            .single();
          
          if (error) {
            console.error('Error fetching user plan info:', error);
            return;
          }
          
          if (data) {
            setUserPlan(data.plan_type);
            setFreePlanUsed(data.free_plan_used);
          }
        }
      };
  
      fetchUserPlanInfo();
    }, []);
  
    // Add this new function to determine if a plan is the current plan
    const isCurrentPlan = (plan) => {
      return plan === planType;
    };

    const plans = [
        {
          name: 'Free',
          price: '$0',
          features: [
            '60 minutes of upload time',
            'File upload up to 2 Gb',
            '1 Upload',
            '1 Saved Project'
          ],
          buttonText: freePlanUsed ? 'Plan Used' : 'Get Started',
          type: 'free'
        },
        {
          name: 'Starter',
          price: billingCycle === 'monthly' ? '$17.99/m' : '$10.99/m',
          annualBilling: billingCycle === 'annual' ? '$131.88 billed annually' : null,
          features: [
            '240 minutes of upload time per month',
            'Unlimited uploads',
            '10 Saved Projects',
            '7 Day Money Back Guarantee'
          ],
          buttonText: 'Get Started',
          type: 'starter',
          productId: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASIC_PRODUCT :'prod_Qb6KeZKXcFlXOS', 
          priceId: {
            monthly: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_LIVE_MONTHLY_BASIC_STRIPE_PRICE_ID : 'price_1Pju7dDZia4zfNELHdBOTa6w',
            annual: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_LIVE_ANNUAL_BASIC_STRIPE_PRICE_ID : 'price_1PnCT4DZia4zfNELzUOhekWj'
          },
          paymentLink: {
            monthly: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_LIVE_MONTHLY_BASIC_STRIPE_PAYMENT_LINK : '',
            annual: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_LIVE_ANNUAL_BASIC_STRIPE_PAYMENT_LINK : ''
          }
        },
        {
          name: 'Pro',
          price: billingCycle === 'monthly' ? '$38.99/m' : '$26.99/m',
          annualBilling: billingCycle === 'annual' ? '$323.88 billed annually' : null,
          features: [
            '600 minutes of upload time per month',
            'Unlimited uploads',
            '20 Saved Projects',
            'New Features',
            '7 Day Money Back Guarantee'
          ],
          buttonText: 'Get Started',
          type: 'pro',
          productId: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_PRO_PRODUCT : 'prod_QeVWEqqbuaBgvk',
          priceId: {
            monthly: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_LIVE_MONTHLY_PRO_STRIPE_PRICE_ID : 'price_1PnCVeDZia4zfNELnom3KZPj',
            annual: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_LIVE_ANNUAL_PRO_STRIPE_PRICE_ID : 'price_1PnCWGDZia4zfNELe7ClhwWN'
          },
          paymentLink: {
            monthly: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_LIVE_MONTHLY_PRO_STRIPE_PAYMENT_LINK : '',
            annual: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_LIVE_ANNUAL_PRO_STRIPE_PAYMENT_LINK : ''
          }
        }
      ];

      // Validate and ensure price IDs exist
      const validatedPlans = plans.map(plan => {
        // Skip validation for free plan
        if (plan.type === 'free') return plan;
      
        // Set appropriate fallback IDs based on plan type
        const fallbackIds = plan.type === 'pro' ? {
          monthly: 'price_1PnCVeDZia4zfNELnom3KZPj',
          annual: 'price_1PnCWGDZia4zfNELe7ClhwWN'
        } : {
          monthly: 'price_1Pju7dDZia4zfNELHdBOTa6w',
          annual: 'price_1PnCT4DZia4zfNELzUOhekWj'
        };
      
        return {
          ...plan,
          priceId: {
            monthly: plan.priceId?.monthly || (process.env.NODE_ENV === 'production' 
              ? (plan.type === 'pro' 
                ? process.env.REACT_APP_PROD_LIVE_MONTHLY_PRO_STRIPE_PRICE_ID 
                : process.env.REACT_APP_PROD_LIVE_MONTHLY_BASIC_STRIPE_PRICE_ID)
              : fallbackIds.monthly),
            annual: plan.priceId?.annual || (process.env.NODE_ENV === 'production'
              ? (plan.type === 'pro'
                ? process.env.REACT_APP_PROD_LIVE_ANNUAL_PRO_STRIPE_PRICE_ID
                : process.env.REACT_APP_PROD_LIVE_ANNUAL_BASIC_STRIPE_PRICE_ID)
              : fallbackIds.annual)
          }
        };
      });
      
      // Log any missing price IDs for debugging (excluding free plan)
      validatedPlans.forEach(plan => {
        if (plan.type !== 'free' && (!plan.priceId?.monthly || !plan.priceId?.annual)) {
          console.error(`Missing price IDs for plan: ${plan.name}`, {
            monthly: plan.priceId?.monthly,
            annual: plan.priceId?.annual
          });
        }
      });
  
    const handleSubscribe = async (planType) => {
        if (planType === 'free' && freePlanUsed) {
          alert('You have already used your free plan. Please choose a paid plan to continue.');
          return;
        }
      
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) {
            throw new Error('User not found');
          }
      
          const now = new Date();
          const oneMonthFromNow = new Date(now.setMonth(now.getMonth() + 1));
      
          const subscriptionData = {
            user_id: user.id,
            plan_type: planType,
            status: 'active',
            usage: 0,
            upload_count: 0,
            current_period_end: oneMonthFromNow.toISOString(),
            auto_renew: planType !== 'free'
          };
      
          if (planType === 'free') {
            subscriptionData.free_plan_used = true;
          }
      
          if (planType === 'free') {
            const { error } = await supabase
              .from('subscriptions')
              .upsert(subscriptionData);
      
            if (error) throw error;
      
            setUserPlan('free');
            setFreePlanUsed(true);
            setSubscriptionStatus('active');
            checkSubscriptionStatus(user.id);
            alert('Free plan activated successfully!');
            onNavigate('menu');
          } else {
            const selectedPlan = plans.find(plan => plan.type === planType);
            if (!selectedPlan) {
              throw new Error('Invalid plan type');
            }
      
            const priceId = selectedPlan.priceId[billingCycle];

            console.log('Creating checkout session with:', {
                priceId,
                planType,
                billingCycle
              });
            
            const response = await fetch(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_CONTENT_SERVICE_URL : process.env.REACT_APP_API_URL}/create-checkout-session`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                priceId,
                userId: user.id,
                planType: planType
              }),
            });
      
            const session = await response.json();
            window.location.href = session.url;
          }
        } catch (error) {
          console.error('Error updating subscription:', error);
          alert(`Failed to update subscription. Error: ${error.message}`);
        }
      };
  
      return (
        <div className="subscription-management">
          <button className="back-arrow" onClick={() => onNavigate('menu')}>&#8592; Back</button>
          <h1>Choose Your Plan</h1>
          <h2>Current Plan: {planType || 'None'}</h2>
        <div className="billing-toggle">
          <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={billingCycle === 'annual'}
              onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly')}
            />
            <span className="slider round"></span>
          </label>
          <span className={billingCycle === 'annual' ? 'active' : ''}>Yearly</span>
        </div>
        {planType !== 'free' && planType !== '' && (
        <button 
          className="button-9 cancel-subscription"
          onClick={handleCancelSubscription}
        >
          Cancel Subscription
        </button>
      )}
        <div className="plans-container">
        {plans.map((plan) => (
          <div key={plan.name} className="plan-card">
            <h2>{plan.name}</h2>
            <p className="price">{plan.price}</p>
            {plan.annualBilling && <p className="annual-billing">{plan.annualBilling}</p>}
            <ul>
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <button 
  className={`button-9 ${plan.type === 'free' && freePlanUsed ? 'disabled' : ''} ${isCurrentPlan(plan.type) ? 'current-plan' : ''}`}
  onClick={() => handleSubscribe(plan.type)}
  disabled={(plan.type === 'free' && freePlanUsed) || isCurrentPlan(plan.type)}
>
  {isCurrentPlan(plan.type) ? 'Current Plan' : plan.buttonText}
</button>
      <div className="plan-arrow"></div>
      <div className="plan-recommendation">
        {plan.type === 'free' && 'Try it for free!'}
        {plan.type === 'starter' && 'Good for Casual Podcasters!'}
        {plan.type === 'pro' && 'Recommended for most podcasters!'}
      </div>
    </div>
  ))}
</div>
      </div>
    );
  }

  // Add these functions near your other parsing functions
  const parseLinkedinPosts = (postText) => {
    if (!postText) return ['No LinkedIn posts available'];
    
    // Match content between "Post 1:", "Post 2:", etc.
    const postRegex = /Post \d+:([\s\S]*?)(?=Post \d+:|$)/g;
    const matches = [...postText.matchAll(postRegex)];
    
    if (matches && matches.length > 0) {
      return matches.map(match => match[1].trim());
    }
    
    // Fallback to paragraph splitting if no post markers found
    const paragraphs = postText.split('\n\n').filter(p => p.trim());
    
    if (paragraphs.length > 1) {
      return paragraphs;
    }
    
    return [postText];
  };

  const parseFacebookPosts = (postText) => {
    console.log('Parsing Facebook post (raw):', postText);
    console.log('Facebook post type:', typeof postText);
    
    // If post is undefined, null, or empty
    if (!postText) {
      console.log('Facebook post is empty or null');
      return ['No Facebook post available'];
    }
    
    // Split by double newlines to find natural paragraphs
    const paragraphs = postText.split('\n\n').filter(p => p.trim());
    
    // If we have multiple paragraphs, use those as separate posts
    if (paragraphs.length > 1) {
      return paragraphs;
    }
    
    // Otherwise just return the single post
    return [postText];
  };

const formatBlogPost = (blogPostText) => {
  if (!blogPostText) return 'No blog post available';

  // Remove any asterisks that might be in the title
  return blogPostText.replace(/\*\*/g, '').replace(/\*/g, '');
};

export default App;